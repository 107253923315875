<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Choix de la cible et préparation du modèle</h1>
    <div class="item-wizard">
      <p class="required-field">Quel sera le site gestionnaire du futur modèle de conditionnement de vos points de livraison client ? </p>
      <dx-lookup
        [dataSource]="findAllLocalSites()"
        [value]="idSelectedSite"
        valueExpr="id"
        displayExpr="libelle"
        [searchEnabled]="true"
        [placeholder]="sitePlaceholder"
        (onSelectionChanged)="onChangeSite($event)">
        <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
      </dx-lookup>
    </div>
    <div class="item-wizard">
      <p class="required-field">Quel nom souhaitez-vous attribuer au modèle de point de livraison client pour votre conditionnement ? </p>
      <input type="text" class="input-text" name="labelModelePlc" [placeholder]="placeholderLabelModelePlc" [(ngModel)]="labelModelPlc" />
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 8/13 : Choix de la cible et préparation du modèle - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
            class="mg-r-5"
            type="button"
            (click)="next()">
       Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
