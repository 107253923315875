import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Subscription} from "rxjs";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {
  ConditionnementsVarianteService
} from "../../../core/services/conditionnements/conditionnement-variante.service";
import ConditionnementVarianteDTO from "../../../core/dtos/conditionnement/conditionnement-variante-dto";
import ConditionnementDTO from "../../../core/dtos/conditionnement/conditionnement-dto";
import {VariantesService} from "../../../core/services/conditionnements/variantes.service";
import {ConditionnementsService} from "../../../core/services/conditionnements/conditionnements.service";
import VarianteDTO from "../../../core/dtos/conditionnement/variante-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-4',
  templateUrl: './wizard-configuration-step-4.component.html',
  styleUrls: ['./wizard-configuration-step-4.component.scss']
})
export class WizardConfigurationStepFourComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  conditionnementsVariantes: any[] = [];

  variantes: VarianteDTO[] = [];

  conditionnements: ConditionnementDTO[] = [];

  subConditionnementsVariantes: Subscription;

  currentSite: SiteDTO;

  subVariantes: Subscription;

  subConditionnements: Subscription;

  conditionnementSelected: ConditionnementDTO;

  variantesSelected: VarianteDTO[] = [];

  displayForm: boolean = false;

  constructor(private auth2Svc: Auth2Service,
              private cvSvc: ConditionnementsVarianteService,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private variantesSvc: VariantesService,
              private conditionnementSvc: ConditionnementsService,
              private i8nSvc: InternationalizationService,
              private toastSvc: ToastService,
              private wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_CONFIG_REFERENTIEL_COMBINAISONS;
  }

  ngOnInit() {
    this.initCurrentSite();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subConditionnementsVariantes);
    this.utilsSvc.unsubscribe(this.subVariantes);
    this.utilsSvc.unsubscribe(this.subConditionnements);
  }

  toogleDisplayForm = (): void => {
    this.displayForm = true;
    let elements = document.getElementsByClassName("container-table");
    let elt: HTMLElement = elements[0] as HTMLElement;
    if (elt)
      elt.style.height = "calc(50vh - 15px)";
  }

  initCurrentSite = (): void => {
    const dataStepOne: any = this.wizardSvc.getDataStepOne();
    const idSelectedSite = dataStepOne.idSelectedSite;
    this.currentSite = this.auth2Svc.utilisateur.siteListLocaux.find(site => site.id === idSelectedSite);
    this.findAllCombinaisons();
  }

  findAllCombinaisons = (): void => {
    this.subConditionnementsVariantes = this.cvSvc.getAll()
      .subscribe((response) => {
        const results: ConditionnementVarianteDTO[] = response.resultList;
        this.conditionnementsVariantes = results.reduce((result, o) => {
            let idx = result.findIndex(r => r.conditionnement.id === o.conditionnement.id);
            let variante = { id: o.variante.id, libelle: o.variante.libelle };

            if(idx === -1)
              result.push({ id: o.conditionnement.id, conditionnement: { id: o.conditionnement.id, libelle: o.conditionnement.libelle }, variantes: [variante]});
            else
              result[idx].variantes.push(variante);
            return result;
          } , []);

        this.findAllVariantes();
        this.findAllConditionnements();
      });
  }

  findAllVariantes = (): void => {
    this.subVariantes = this.variantesSvc.getAll()
      .subscribe(response => {
        this.variantes = response.resultList;
      });
  }

  findAllConditionnements = (): void => {
    this.subConditionnements = this.conditionnementSvc.getAll()
      .subscribe(response => {
        this.conditionnements = response.resultList;
        this.conditionnements = this.conditionnements.filter(c => this.conditionnementsVariantes.filter(cv => c.id === cv.conditionnement.id).length === 0);
      });
  };

  addConditionnementVariante = (): void => {
    if (!this.conditionnementSelected || !this.variantesSelected.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode('EMPTY_LINE_TABLE', null));
      return;
    }
    this.conditionnementsVariantes.push({ id: this.conditionnementSelected.id, conditionnement: Object.assign({}, this.conditionnementSelected), variantes: [...this.variantesSelected] });
    this.conditionnements = this.conditionnements.filter(c => this.conditionnementsVariantes.filter(cv => c.id === cv.conditionnement.id).length === 0);
    this.displayForm = false;
    let elements = document.getElementsByClassName("container-table");
    let elt: HTMLElement = elements[0] as HTMLElement;
    if (elt)
      elt.style.height = "calc(56vh - 15px)";

    const dataFromStepOne = this.wizardSvc.getDataStepOne(); // On utilise le site de l'étape 1 pour les référentiels
    const site = { id: dataFromStepOne.idSelectedSite } as SiteDTO;
    const conditionnementsVariantesToSave: ConditionnementVarianteDTO[] = [];
    this.variantesSelected.forEach((variante) => {
      const conditionnementVariante: ConditionnementVarianteDTO = new ConditionnementVarianteDTO();
      conditionnementVariante.id = undefined;
      conditionnementVariante.site = site;
      conditionnementVariante.conditionnement = Object.assign({}, this.conditionnementSelected);
      conditionnementVariante.variante = Object.assign({}, variante);
      conditionnementsVariantesToSave.push(conditionnementVariante);
    });

    this.conditionnementSelected = undefined;
    this.variantesSelected = [];
    this.cvSvc.save(conditionnementsVariantesToSave)
      .subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le(s) association(s) ont été ajouté(s) avec succès`);
      });
  }

  getVariantesForConditionnement = (row: any): string => {
    return  (row && row.variantes) ? row.variantes
      .map(v => v.libelle)
      .join(',') : '';
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      conditionnementsVariantes: this.conditionnementsVariantes
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
