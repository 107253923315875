import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GestionproduitsRoutingModule} from './gestionproduits-routing.module';
import {GestionproduitsComponent} from './gestionproduits.component';
import {SharedModule} from '../shared/shared.module';
import {GrilleproduitsComponent} from './grilleproduits/grilleproduits.component';
import {ProduitComponent} from './produit/produit.component';

import {DragDropConfig, DragDropService, DragDropSortableService} from 'ng2-dnd';

import {ArticleProduitComponent} from './produit/article-produit/article-produit.component';
import {AllergeneProduitComponent} from './produit/allergene-produit/allergene-produit.component';

import {FichetechniqueProduitComponent} from './produit/fichetechnique-produit/fichetechnique-produit.component';
import {ProduitsrecetteComponent} from './produit/fichetechnique-produit/produitsrecette/produitsrecette.component';
import {GemrcnProduitComponent} from './produit/gemrcn-produit/gemrcn-produit.component';
import {GemrcnResolverService} from './produit/gemrcn-produit/gemrcn-resolver.service';
import {ModeOperatoireProduitComponent} from './produit/mode-operatoire-produit/mode-operatoire-produit.component';
import {FicheidentiteProduit2Component} from './produit/ficheidentite-produit2/ficheidentite-produit2.component';
import {CiqualComponent} from './produit/ciqual/ciqual.component';
import {DeclinaisonProduitDialogEditComponent} from './produit/declinaisons-produit/declinaison-produit-dialog-edit/declinaison-produit-dialog-edit.component';
import {DeclinaisonsProduitComponent} from './produit/declinaisons-produit/declinaisons-produit.component';
import {CiqualDialogEditComponent} from './produit/ciqual/ciqual-dialog-edit/ciqual-dialog-edit.component';
import {AllergeneResolverService} from './produit/allergene-produit/allergene-resolver.service';
import {AppellationsComponent} from './produit/appellations/appellations.component';
import {FtSearchPanelComponent} from './produit/fichetechnique-produit/search-panel/ft-search-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogComponent} from './produit/article-produit/dialog/dialog.component';
import {InfosComponent} from './produit/article-produit/dialog/infos/infos.component';
import {ArticleAllergenesComponent} from './produit/article-produit/dialog/allergenes/article-allergenes.component';
import {ArticleAppellationsComponent} from './produit/article-produit/dialog/appellations/article-appellations.component';
import {MarchesProduitComponent} from './produit/marches-produit/marches-produit.component';
import {
    DxBarGaugeModule,
    DxCircularGaugeModule,
    DxDataGridModule, DxHtmlEditorModule,
    DxNumberBoxModule,
    DxPieChartModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule
} from 'devextreme-angular';
import {MarcheProduitComponent} from './produit/marches-produit/marche-produit/marche-produit.component';
import {NgxGaugeModule} from 'ngx-gauge';
import {TableModule} from 'primeng/table';

import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import {MenuModule} from 'primeng/menu';
import {CardModule} from 'primeng/card';
import {TabMenuModule} from 'primeng/tabmenu';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {SliderModule} from 'primeng/slider';
import {ListboxModule} from 'primeng/listbox';
import {AccordionModule} from 'primeng/accordion';
import {DialogListPlatComponent} from "./produit/declinaisons-produit/dialog-list-plat/dialog-list-plat.component";
import {DialogReplaceDenreeComponent} from "./produit/declinaisons-produit/dialog-list-plat/dialog-replace-denree/dialog-replace-denree.component";
import {InputTextModule} from "primeng/inputtext";
import {ImplementationModeleConditionnementPlatComponent} from "./produit/conditionnement/modele-conditionnement-plat/implementation-modele-conditionnement-plat.component";
import {DialogEditImplementationModeleConditionnementProduitComponent} from "./produit/conditionnement/modele-conditionnement-plat/dialog-edit-conditionnement-produit/dialog-edit-implementation-modele-conditionnement-produit.component";
import {VisualisationMcpParametrageComponent} from "./produit/conditionnement/modele-conditionnement-plat/visualisation/visualisation-mcp-parametrage.component";
import {DialogChangeUniteTechniqueComponent} from "./produit/declinaisons-produit/declinaison-produit-dialog-edit/dialog-change-unite-technique/dialog-change-unite-technique.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        GestionproduitsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        DropdownModule,
        OverlayPanelModule,
        MenuModule,
        CardModule,
        TabMenuModule,
        CheckboxModule,
        SelectButtonModule,
        DialogModule,
        TabViewModule,
        RadioButtonModule,
        MultiSelectModule,
        SliderModule,
        ListboxModule,
        AccordionModule,
        DxDataGridModule,
        DxPieChartModule,
        DxCircularGaugeModule,
        DxBarGaugeModule,
        DxToolbarModule,
        NgxGaugeModule,
        InputTextModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxTagBoxModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxHtmlEditorModule
    ],
  providers: [
    GemrcnResolverService,
    AllergeneResolverService,
    DragDropService,
    DragDropSortableService,
    DragDropConfig
  ],
  entryComponents:[

  ],
  declarations: [
    GestionproduitsComponent,
    GrilleproduitsComponent,
    ProduitComponent,
    ArticleProduitComponent,
    AllergeneProduitComponent,
    FichetechniqueProduitComponent,
    ProduitsrecetteComponent,
    GemrcnProduitComponent,
    ModeOperatoireProduitComponent,
    FicheidentiteProduit2Component,
    DeclinaisonsProduitComponent,
    DeclinaisonProduitDialogEditComponent,
    CiqualComponent,
    CiqualDialogEditComponent,
    AppellationsComponent,
    FtSearchPanelComponent,
    DialogComponent,
    InfosComponent,
    ArticleAllergenesComponent,
    ArticleAppellationsComponent,
    MarchesProduitComponent,
    MarcheProduitComponent,
    DialogListPlatComponent,
    DialogReplaceDenreeComponent,
    ImplementationModeleConditionnementPlatComponent,
    DialogEditImplementationModeleConditionnementProduitComponent,
    VisualisationMcpParametrageComponent,
    DialogChangeUniteTechniqueComponent
  ],
  exports: [FichetechniqueProduitComponent, DialogComponent]

})
export class GestionproduitsModule {
}
