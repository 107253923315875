import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {FiltersContextOrganization} from "../../dtos/filters-context-organization-dto";

@Injectable({
  providedIn: 'root'
})
export class OrganisationsTourneesService {

  private subjectOpenDialog = new Subject<number>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectAnnounceOrganisationTourneeSaved = new Subject();
  organisationOrganisationTourneeSaved$ = this.subjectAnnounceOrganisationTourneeSaved.asObservable();

  constructor(private httpSvc: HttpService) {
  }

  deleteOrganizationsByFilters = (rowsToRemove: number[]): Observable<ResponseWrapper<boolean>> => {
    let params: string = '?1=1';
    if (rowsToRemove?.length) params += `&idsToDelete=${rowsToRemove.join(',')}`
    return this.httpSvc.delete(`dolrest/tournees/organisations/delete${params}`);
  }

  fetchFiltersContext = (): Observable<ResponseWrapper<FiltersContextOrganization>> => this.httpSvc.get(`dolrest/tournees/organisations/filtersContext`);

  announceOpenEditionDialog = (id?: number) => this.subjectOpenDialog.next(id);

}
