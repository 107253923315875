<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-15"
        label="CRÉER"
        iconClass="fa fa-plus"
        tooltip="Création d'une configration portail pour un point de livraison"
        (onClick)="openEditConfigurationPortailPlc(createEmptyPortailCfg())">
      </yo-button>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        icon="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  class="pt-2"
  [dataSource]="configurationPortailPlcList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

  <dxi-column dataField="plc.id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="client.libelle" [allowGrouping]="true" [autoExpandGroup]="true" [groupIndex]="0"
              caption="Client" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="plc.libelle" caption="Point de Livraison" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="jourLimiteSaisi" caption="Jour limite de saisi" cellTemplate="jourCellTemplate"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="heureLimiteSaisie" caption="Heure limite de saisie" cellTemplate="heureSaisieCellTemplate"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="nombreUniteBlocage" caption="Fin de l'interdiction de la saisie d'effectif"
              cellTemplate="blocageCellTemplate" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="minValeurSaisie" caption="Effectif minimum à saisir" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="maxValeurSaisie" caption="Effectif maximum à saisir" [allowFiltering]="true"></dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditConfigurationPortailPlc(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-edit"
                    yoTooltip="Modifier la configuration">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'jourCellTemplate'">
    {{ cell.row.data.jourLimiteSaisi.charAt(0).toUpperCase() + cell.row.data.jourLimiteSaisi.substr(1).toLowerCase() }}
  </div>

  <div *dxTemplate="let cell of 'blocageCellTemplate'">
    Dans {{ cell.row.data.nombreUniteBlocage}}  {{ cell.row.data.uniteBlocage === 'week' ? 'semaine(s)' : 'jour(s)'}}
  </div>

  <div *dxTemplate="let cell of 'heureSaisieCellTemplate'">
    A {{ cell.row.data.heureLimiteSaisie}}h
  </div>

  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} ligne(s) de configuration">
  </dxo-pager>

</dx-data-grid>


<yo-configuration-portail-plc-form></yo-configuration-portail-plc-form>
