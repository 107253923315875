<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  (onHide)="closeDialog()"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      {{titleDialog}}
    </div>
  </ng-template>
  <div class="mg-t-5" style=" overflow-y:auto;overflow-x: hidden">

    <form>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Point de livraison client <span class="danger-color">*</span>
        </div>
        <div class="col-md-8">
          <dx-tag-box *ngIf="mode === ModeEnum.CREATE" [multiline]="false" [searchEnabled]="true"
                      placeholder="Veuillez sélectionner le point de livraison client..." displayExpr="libelle"
                      valueExpr="id" name="plcsSelected" [(ngModel)]="plcsSelected" [value]="plcsSelected"
                      [dataSource]="plcList">
          </dx-tag-box>
          <dx-select-box *ngIf="mode === ModeEnum.UPDATE" [searchEnabled]="true"
                         placeholder="Veuillez sélectionner le point de livraison client..." displayExpr="libelle"
                         valueExpr="id" name="plcSelected" [(ngModel)]="plcSelected" [value]="plcSelected"
                         [dataSource]="plcList">
          </dx-select-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" for="jourLimiteSaisi" title="Libellé">Jour limite de saisie <span
          class="danger-color">*</span></div>
        <div class="col-md-8">
          <dx-select-box [searchEnabled]="true" placeholder="Veuillez sélectionner le jour limite de saisie..."
                         id="jourLimiteSaisi" name="jourLimiteSaisi" displayExpr="viewValue"
                         [(ngModel)]="jourLimiteSaisiSelected" [value]="jourLimiteSaisiSelected"
                         [dataSource]="jourLimitSaisiOption">
          </dx-select-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Heure limite saisie <span
          class="danger-color">*</span></div>
        <div class="col-md-8">
          <dx-number-box [value]="portailConfig?.heureLimiteSaisie" [(ngModel)]="heureLimiteSaisie"
                         name="heureLimiteSaisie" [step]="1" [showSpinButtons]="true"
                         [showClearButton]="true"></dx-number-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Unité de la période marquant la fin de l'interdiction
          de la saisie d'effectif <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <dx-select-box [searchEnabled]="true" placeholder="Veuillez sélectionner l'unité de la période..."
                         id="uniteBlocage" name="uniteBlocage" displayExpr="libelle" [(ngModel)]="uniteBlocageSelected"
                         [value]="uniteBlocageSelected" [dataSource]="unitBlocageOptionList">
          </dx-select-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Fin de l'interdiction de la saisie d'effectif <span
          class="danger-color">*</span></div>
        <div class="col-md-8">
          <dx-number-box [value]="portailConfig?.nombreUniteBlocage" [(ngModel)]="nombreUniteBlocage"
                         name="nombreUniteBlocage" [step]="1" [showSpinButtons]="true"
                         [showClearButton]="true"></dx-number-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Effectif minimum à saisir <span class="danger-color">*</span>
        </div>
        <div class="col-md-8">
          <dx-number-box [value]="portailConfig?.minValeurSaisie" [(ngModel)]="minValeurSaisie" name="minValeurSaisie"
                         [step]="1" [showSpinButtons]="true" [showClearButton]="true"></dx-number-box>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Effectif maximum à saisir <span class="danger-color">*</span>
        </div>
        <div class="col-md-8">
          <dx-number-box [value]="portailConfig?.maxValeurSaisie" [(ngModel)]="maxValeurSaisie" name="maxValeurSaisie"
                         [step]="1" [showSpinButtons]="true" [showClearButton]="true"></dx-number-box>
        </div>
      </div>
    </form>

    <hr>
    <div class="d-flex justify-content-end">

      <span class="mg-r-5">
        <yo-button buttonClass="cta-success" iconClass="fas fa-save" (onClick)="saveConfigPortail()"></yo-button>
      </span>

      <yo-button
        class="mg-l-5"
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        iconClass="fas fa-times">
      </yo-button>
    </div>
  </div>
</p-dialog>
