// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.5-rc-9376(f3b2ae242d)-C28/06/2023-12:20:25-B28/06/2023-12:21:40' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.5-rc-9376(f3b2ae242d)-C28/06/2023-12:20:25-B28/06/2023-12:21:40",
  branch: "master",
  latestTag: "5.5-rc",
  revCount: "9376",
  shortHash: "f3b2ae242d",
  longHash: "f3b2ae242d5ef8e83cf64611e6a725d9897555e6",
  dateCommit: "28/06/2023-12:20:25",
  dateBuild: "28/06/2023-12:21:40",
  buildType: "Ansible",
  } ;
