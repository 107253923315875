<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="'auto'"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [(fullScreen)]="fullScreen"
>

  <dx-popup
    [(visible)]="displayPopupPeriods"
    [showCloseButton]="true"
    [width]="500"
    [height]="'auto'"
    title="Ajouter une période"
  >
    <div class="row">
      <div class="col-sm-6">
        <div class="genlabel required">Date de début <span class="danger-color">*</span> </div>
        <dx-date-box #start [(value)]="dateStart"  type="date"> </dx-date-box>
      </div>
      <div class="col-sm-6">
        <div class="genlabel required">Date de fin <span class="danger-color">*</span> </div>
        <dx-date-box #end [(value)]="dateEnd" type="date"> </dx-date-box>
      </div>
    </div>
    <div class="row mg-t-15">
      <div class="col-sm-12 text-right">
        <yo-button
          buttonClass="cta-success"
          iconClass="fas fa-save"
          label="Enregistrer"
          (onClick)="addPeriod()">
        </yo-button>
      </div>
    </div>
  </dx-popup>

  <div class="yo-card">
    <div class="yo-card-header">
      Informations générales
    </div>
    <div class="yo-card-body">
      <ng-container *ngIf="organisationTournees">
        <div class="row">
          <div class="col-sm-6">
            <div class="row no-margin">
              <div class="col-sm-2 align-flex-center genlabel required">Libellé <span class="danger-color">*</span> </div>
              <div class="col-sm-10">
                <div class="input-group-sm">
                  <dx-text-box [(ngModel)]="organisationTournees.label"></dx-text-box>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row no-margin">
              <div class="col-sm-2 align-flex-center genlabel required">Site <span class="danger-color">*</span> </div>
              <div class="col-sm-10">
                <dx-lookup
                  [dataSource]="findAllLocalSites()"
                  [value]="idSelectedSite"
                  valueExpr="id"
                  displayExpr="libelle"
                  [searchEnabled]="true"
                  [placeholder]="sitePlaceholder"
                  (onSelectionChanged)="onChangeSite($event)"
                >
                  <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
                </dx-lookup>
              </div>
            </div>
          </div>
        </div>
        <div class="row mg-t-10 mg-b-10">
          <div class="col-sm-12">
            <yo-button
              class="mg-r-10"
              iconClass="fa fa-plus"
              (onClick)="openDialogPeriods()"
              label="Ajouter une période"
              tooltip="Ajouter une période">
            </yo-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <dx-data-grid
              [dataSource]="organisationTournees.periods"
              [height]="110"
              class="data-grid"
              [hoverStateEnabled]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [(selectedRowKeys)]="selectedRowsPeriods"
              noDataText="Aucune période"
              columnResizingMode="widget">
              <dxo-selection
                selectAllMode="allPages"
                showCheckBoxesMode="always"
                mode="multiple"
              ></dxo-selection>
              <dxi-column caption="Date de début" dataType="date" dataField="start" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
              <dxi-column caption="Date de fin" dataType="date" dataField="end" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="yo-card">
    <div class="yo-card-header">
      Visualisation de l'organisation de tournée(s) par jour de livraison
    </div>
    <div id="wrapper-grid-vizualisation" class="yo-card-body">
      <ng-container *ngIf="organisationTournees && organisationTournees.informationsByDeliveryDay">
        <dx-tab-panel height="100%">
        <dxi-item [title]="day" icon="event" *ngFor="let day of deliveryDays">
          <dx-data-grid
            [dataSource]="organisationTournees.informationsByDeliveryDay[day.toUpperCase()]"
            height="100%"
            width="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            noDataText="Aucune période"
            columnResizingMode="widget">
            <dxi-column caption="Tournée" dataField="tournee.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Ordre" dataField="order" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Point de livraison client" dataField="pointLivraison.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Jour de consommation" dataField="consumptionDay" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Repas" dataField="repas.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Prestation" dataField="prestation.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Famille de plat" dataField="famillePlat.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column caption="Plat" dataField="plat.libelle" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
      </ng-container>
    </div>
  </div>
  <div class="yo-card">
    <div class="yo-card-header">
      Ajout d'une ligne pour l'organisation
    </div>
    <div class="yo-card-body">
      <div class="row">
        <div class="col-sm-3">
          <dx-select-box #inputTournees
                      [dataSource]="tournees" placeholder="Sélectionner une tournée..." displayExpr="libelle" [searchEnabled]="true"
                      [showSelectionControls]="true" (onValueChanged)="onChangeTournee($event)">
          </dx-select-box>
        </div>
        <div class="col-sm-3">
          <dx-select-box #inputPointsLivraisonsClient
                         [dataSource]="pointsLivraisons" placeholder="Sélectionner un point de livraison client..." displayExpr="libelle" [searchEnabled]="true"
                         [showSelectionControls]="true" (onValueChanged)="onChangePointLivraisonClient($event)">
          </dx-select-box>
        </div>
        <div class="col-sm-3">
          <dx-select-box #inputJoursLivraisons
                         [dataSource]="deliveryDays" placeholder="Sélectionner un jour de livraison..." [searchEnabled]="true"
                         [showSelectionControls]="true" (onValueChanged)="onChangeJourLivraison($event)">
          </dx-select-box>
        </div>
        <div class="col-sm-3">
          <dx-select-box #inputJoursConso
                         [dataSource]="currentConsumptionDays" placeholder="Sélectionner un jour de consommation..." [searchEnabled]="true"
                         [showSelectionControls]="true" (onValueChanged)="onChangeJourConso($event)">
          </dx-select-box>
        </div>
      </div>
      <div class="row mg-t-15">
        <div class="col-sm-3">
          <dx-tag-box #inputRepas
                         [dataSource]="repas" placeholder="Sélectionner un ou plusieurs repas..." displayExpr="libelle" [searchEnabled]="true"
                         [showSelectionControls]="true" (onValueChanged)="onChangeRepas($event)">
          </dx-tag-box>
        </div>
        <div class="col-sm-3">
          <dx-tag-box #inputPrestations applyValueMode="useButtons"
                      [dataSource]="prestations" placeholder="Sélectionner une ou plusieurs prestation(s)..." displayExpr="libelle" [searchEnabled]="true"
                      [showSelectionControls]="true" (onValueChanged)="onChangePrestation($event)">
          </dx-tag-box>
        </div>
        <div class="col-sm-3">
          <dx-tag-box #inputRepas
                      [dataSource]="repas" placeholder="Sélectionner une ou plusieurs famille(s) de plats..." displayExpr="libelle" [searchEnabled]="true"
                      [showSelectionControls]="true" (onValueChanged)="onChangeRepas($event)">
          </dx-tag-box>
        </div>
        <div class="col-sm-3">
          <dx-tag-box #inputRepas
                      [dataSource]="repas" placeholder="Sélectionner un ou plusieurs plat(s)..." displayExpr="libelle" [searchEnabled]="true"
                      [showSelectionControls]="true" (onValueChanged)="onChangeRepas($event)">
          </dx-tag-box>
        </div>
      </div>
      <div class="row mg-t-15">
        <div class="col-sm-12 text-right">
          <yo-button iconClass="fas fa-plus" label="Ajouter la ligne"></yo-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 custom-button-container-right">
      <yo-button iconClass="fas fa-list-check" label="Vérifier la cohérence"></yo-button>
      <yo-button buttonClass="cta-success" iconClass="fas fa-save" label="Enregistrer"></yo-button>
    </div>
  </div>

  <div>

  </div>
</dx-popup>
