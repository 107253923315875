<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Sélection des plats à conditionner</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de sélectionner à travers cette étape les plats que vous désirez conditionner. Le tableau de gauche représente la liste
        des plats à conditionner compatibles avec votre modèle de plats tandis que le tableau de droite représente votre sélection dans lequel vous
        pouvez supprimer des lignes en cas d'erreur.
        Chaque plat sélectionné sera associé au modèle de conditionnement plat actuel ainsi que les unités de production associés à ce dernier.
      </p>
      <div class="row mg-t-10">
        <div class="col-lg-4">
          <dx-tag-box
            #dxTypeFab
            placeholder="Filtrer les plats par type de fabrication..."
            (onValueChanged)="onChangeTypeFabricationFilter($event)"
            [dataSource]="allTaches"
            [showSelectionControls]="true"
            displayExpr="libelle"
            valueExpr="id"
            [searchEnabled]="true"
            applyValueMode="instantly">
          </dx-tag-box>
        </div>
        <div class="col-lg-4">
          <dx-tag-box
            #dxRegime
            placeholder="Filtrer les plats par régime..."
            (onValueChanged)="onChangeRegimeFilter($event)"
            [dataSource]="allRegimes"
            [showSelectionControls]="true"
            displayExpr="libelle"
            valueExpr="id"
            [searchEnabled]="true"
            applyValueMode="instantly">
          </dx-tag-box>
        </div>
        <div class="col-lg-4">
          <dx-tag-box
            #dxSites
            placeholder="Filtrer les plats par site..."
            (onValueChanged)="onChangeSiteFilter($event)"
            [dataSource]="sitesUtilisateurConnecte"
            [showSelectionControls]="true"
            displayExpr="libelle"
            valueExpr="id"
            [searchEnabled]="true"
            applyValueMode="instantly">
          </dx-tag-box>
        </div>
      </div>
      <div class="row mg-t-10 mg-b-15">
        <div class="col-lg-4">
          <dx-drop-down-box
            #dxFamilleProduit
            [(value)]="treeBoxValue"
            valueExpr="id"
            displayExpr="name"
            placeholder="Filtrer les plats par famille de produit..."
            [showClearButton]="true"
            [dataSource]="treeDataSource"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #dxTreeView
                [dataSource]="allFamillesProduits"
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parent.id"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="true"
                displayExpr="libelle"
                [selectByClick]="true"
                (onItemSelectionChanged)="onChangeParentDenreeSelection($event)"
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
        <div class="col-md-4">
          <dx-text-box #dxCode placeholder="Filtrer les plats par code..." valueChangeEvent="keyup" (onValueChanged)="onChangeCodeFilter($event)">
          </dx-text-box>
        </div>
        <div class="col-md-4">
          <dx-text-box #dxLabel placeholder="Filtrer les plats par libellé..." valueChangeEvent="keyup" (onValueChanged)="onChangePlatLibelleFilter($event)">
          </dx-text-box>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 container-table">
          <dx-data-grid
            [dataSource]="dataSource"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [remoteOperations]="true"
            columnResizingMode="widget"
            #gridPlatsToAdd>

            <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

            <dxi-column dataField="site.libelle" caption="Site" cellTemplate="siteCellTemplate" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="declinaisons" cellTemplate="pdCellTemplate" caption="Déclinaison(s)" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="code" caption="Code" [allowFiltering]="false"></dxi-column>

            <div *dxTemplate="let cell of 'siteCellTemplate'">
              <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"
                       [yoDelayMsTooltip]="1500"></yo-site>
            </div>

            <div class="flex-wrap" *dxTemplate="let cell of 'pdCellTemplate'">
              <label *ngFor="let d of cell.row.data.declinaisons" class="badge badge-secondary zoom cursor">
                {{ d?.libelle }}
              </label>
            </div>


            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-paging [pageSize]="50"></dxo-paging>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>
        </div>
        <div class="col-lg-6 container-table">
          <dx-data-grid
            [dataSource]="produitsSelected"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [(selectedRowKeys)]="selectedRows"
            #gridProduitsSelected>
            <dxo-selection [deferred]="false" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesModeGridProduitsSelected" mode="multiple"></dxo-selection>
            <dxi-column dataField="site.libelle" caption="Site" cellTemplate="siteCellTemplate" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="produitsDeclinaisons" cellTemplate="pdCellTemplate" caption="Déclinaison(s)" [allowFiltering]="false"></dxi-column>

            <div *dxTemplate="let cell of 'siteCellTemplate'">
              <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"
                       [yoDelayMsTooltip]="1500"></yo-site>
            </div>
            <div class="flex-wrap" *dxTemplate="let cell of 'pdCellTemplate'">
              <label *ngFor="let pd of cell.row.data.produitsDeclinaisons" class="badge badge-secondary zoom cursor">
                {{ pd?.declinaison?.libelle }}
              </label>
            </div>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 6/13 : Sélection des plats à conditionner - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Ajouter les lignes sélectionnées"
             class="mg-r-5"
             type="button"
             (click)="addRows()">
      <i class="fas fa-plus"></i> Ajouter les lignes sélectionnées
    </button>
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
    <button  title="Supprimer les lignes sélectionnées"
             class="mg-r-5"
             type="button"
             (click)="deleteRows()">
      Supprimer les lignes sélectionnées <i class="fas fa-trash"></i>
    </button>
  </div>
</div>
