import {UserHasRight} from './services/security/user-has-right';
import {FormatImpression} from './models/format-impression';
import {FormatOrientation} from './models/format-orientation';
import {SelectItem} from 'primeng/api';

export const START_DELETEQUESTION = 'Êtes vous sûr de vouloir supprimer ';

export type MAT_DISPLAY = 'mini' | 'normal';

export const GUID_HELP = `Champ technique utilisé pour la communication avec d'autres logiciels. Ce champ est non modifiable et unique par item.`;

export const VAPID_PUBLIC_KEY = 'BJb0iZXuUy7HepHvNlDJOk204Snta2KL3VrtHWS-hGcdH-xB713Yf4BUwn4dulkgScObLrUxf8NVEmaiSjlA_dI';


export const DATES_FORMAT = {
  DDMMYYYY: 'DDMMYYYY',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYYDDMM: 'YYYYDDMM',
  YYYYMMDD: 'YYYYMMDD',
}

export const DATEPICKER_FR = {
  weekHeader: 'Semaine',
  firstDayOfWeek: 1,
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: `Aujourd'hui`,
  clear: 'Effacer'
};

/**
 * Variante de DATEPICKER_FR pour afficher les noms des mois en libelle court dans l'affichage inline
 */
export const DATEPICKER_FR_INLINE = {
  weekHeader: 'Semaine',
  firstDayOfWeek: 1,
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  monthNames: ['Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai.', 'Juin', 'Juil', 'Aou.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: `Aujourd'hui`,
  clear: 'Effacer'
};

export const ERREURS = {
  SORTIES_PRODUCTION: {
    SPPE_1: 'SPPE_1',
    SPPE_2: 'SPPE_2',
    SPPE_3: 'SPPE_3',
    SPPE_4: 'SPPE_4'
  }
}

export const JOURS_SEMAINES: JourSemaine[] = [
  {value: 1, viewValue: 'LUNDI'},
  {value: 2, viewValue: 'MARDI'},
  {value: 3, viewValue: 'MERCREDI'},
  {value: 4, viewValue: 'JEUDI'},
  {value: 5, viewValue: 'VENDREDI'},
  {value: 6, viewValue: 'SAMEDI'},
  {value: 7, viewValue: 'DIMANCHE'},
];

export const MOMENT_LUNDI = 1;


export const AUTH = {
  'token': 'jwt_token',
  'refreshToken': 'refresh_token',
  'redirectUrl': 'redirect_url',
  'roleNeeded': 'role_needed',
  'licenceFeatureNeeded': 'licence_feature_needed',
  'resultAccess': 'result_access',
  'utilisateur': 'utilisateur',
  'environnement': 'environnement',
  'environnements': 'environnements',
  'preferences': 'preferences',
  'preferencesGlobales': 'preferencesGlobales'
};


export enum DROP_ACTION {
  /**
   * Deposer un produit declinaison dans la grille des menus
   */
  GM_PRODUITDECLINAISON_TO_MENUCOMPOSITION,
  // Mettre un menu à la poubelle
  GM_DROP_TO_TRASH,
  // deplacer un menu composition d'un decoupage repas vers un autre
  GM_MOVE_MENUCOMPOSITION,

}

export const DROP_ZONES = {
  PLANNING: 'planning',
  TRASH: 'trash',
  SAME_MENU_DECOUPAGE_DATE: 'same_menu_decoupage_date'
}

export const MSG_STATUT = {
  CAL: 'CALENDRIER',
  WARN: 'ATTENTION',
  ERROR: 'ERREUR',
  INFO: 'INFO',
  SUCCES: 'SUCCÈS',
  ERROR_400: 'ERREUR 400',
  ERROR_401: 'ERREUR 401',
  ERROR_403: 'ERREUR 403',
  ERROR_404: 'ERREUR 404',
  ERROR_504: 'ERREUR 504',
  ERROR_500: 'ERREUR 500',
  ERROR_503: 'ERREUR 503',
};

export const GESTIONMENUS_PIVOT_PLANNING = [
  {label: 'Jour', value: 1},
  {label: 'Convive', value: 2},
  {label: 'Repas', value: 3},
];

export const USER_TYPE_PREFERENCE = {
  BOOLEAN: 'global.type.boolean',
  NUMBER: 'global.type.integer',
  STRING: 'global.type.string',
  STRINGARR: 'global.type.string.array',
  NUMBERARR: 'global.type.sinteger.array',
  DATE: 'global.type.date',
};


export const MENU_PROFIL = {
  DIET: 1,
  ECONOMAT: 2,
  TOUT: 3
};

export const MENUS_SEARCH_MODES: SelectItem[] = [
  {label: 'Familles de plat', value: 2},
  {label: 'Libre', value: 1},
  {label: 'Plan alimentaire', value: 3},
];

export const USER_PREFERENCE = {

    GESTIONMENUS_COHERENCE_ARTICLE: 'gestionmenus.coherence.article',
    GESTIONMENUS_DISPLAY_NBJOURS: 'gestionmenus.display.nbjours',
    GESTIONMENUS_DISPLAY_FORMSEARCH: 'gestionmenus.display.formsearch',
    GESTIONMENUS_DISPLAY_ALLERGENES: 'gestionmenus.display.allergenes',
    GESTIONMENUS_DISPLAY_APPELLATIONS: 'gestionmenus.display.appellations',
    GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN: 'gestionmenus.display.famillesgemrcn',
    GESTIONMENUS_DISPLAY_COUTS_UNITAIRES: 'gestionmenus.display.couts.unitaires',
    GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES: 'gestionmenus.display.couts.unitaires.ponderes',
    GESTIONMENUS_DISPLAY_TAUX_DE_PRISE: 'gestionmenus.display.taux.de.prise',
    GESTIONMENUS_SELECT_JOURS_SEMAINE: 'gestionmenus.select.jours.semaine',
    GESTIONMENUS_TOOLBAR_SAVEIMMEDIATLY: 'gestionmenus.toolbar.saveimmediatly',
    GESTIONMENUS_PLANNING_PIVOT: 'gestionmenus.planning.pivot',
    GESTIONMENUS_PROFIL: 'gestionmenus.profil',
    GESTIONMENUS_COUTGLOBAL_MODE: 'gestionmenus.coutglobal.mode',
    GESTIONMENUS_CONTRAT: 'gestionmenus.contrat',
    GESTIONMENUS_DATE: 'gestionmenus.date',
    GESTIONMENUS_PRESTATION: 'gestionmenus.prestation',
    GESTIONMENUS_REGIME: 'gestionmenus.regime',
    GLOBAL_PREFERENCE_DISPLAYFAMILLESNUTRITIONNELLES: 'global.preference.displayfamillesnutritionnelles',
    GLOBAL_PREFERENCE_DISPLAYFAMILLESPLANALIMENTAIRE: 'global.preference.displayfamillesplanalimentaire',
    GLOBAL_PREFERENCE_SAVEIMMEDIATELY: 'global.preference.saveimmediately',
    GLOBAL_PREFERENCE_HOMEPAGE: 'global.preference.homepage',
    GLOBAL_PREFERENCE_LASTPAGE: 'global.preference.lastpage',
    GLOBAL_PREFERENCE_STARTPAGE: 'global.preference.startpage',
    GLOBAL_PREFERENCE_ENABLE_NOTIFICATIONS: 'global.preference.enable.notifications',
    GLOBAL_PREFERENCE_ENABLE_COPY_CARBONE: 'global.preference.emails.enable.cc',
    GLOBAL_PREFERENCE_ID_DEFAULT_ENVIRONNEMENT: 'global.preference.iddefaultenvironnement',
    GESTIONMENUS_DUPLICATION_PORTER_EFFECTIFS: 'gestionmenus.duplication.portereffectifs',
    GESTIONMENUS_DUPLICATION_ECRASER_MENUS_EXISTANTS: 'gestionmenus.duplication.ecrasermenusexistants',
    GESTIONMENUS_RECHERCHE_MODE: 'gestionmenus.recherche.mode',
    LISTEBESOINS_CHOIX_VUE: 'listebesoins.choix.vue',
    LISTEBESOINS_ARTICLES_CHOIX_VUE: 'listebesoins.articles.choix.vue',
    LISTEBESOINS_ARTICLES_CHOIX_GROUPE: 'listebesoins.articles.choix.groupe',

    CALCUL_PRICE_MEALS_AFTER_SAVE_ITEM: 'global.preference.calcul.price.meals.save.item',
    CALCUL_PRICE_MEALS_AFTER_SELECT_MANUAL_ITEM_PREFERED: 'global.preference.calcul.price.select.manual.item.prefered',
    CALCUL_PRICE_MEALS_AFTER_ADD_FORBIDDEN_FOOD: 'global.preference.calcul.price.add.forbidden.food',
    CALCUL_PRICE_MEALS_AFTER_DELETE_FORBIDDEN_FOOD: 'global.preference.calcul.price.delete.forbidden.food',
    CALCUL_PRICE_MEALS_AFTER_SWITCH_FOOD: 'global.preference.calcul.price.switch.food',
    CALCUL_PRICE_MEALS_AFTER_DUPLICATE_PRODUCT: 'global.preference.calcul.price.duplicate.product',
    CALCUL_PRICE_MEALS_AFTER_IMPORT_CA_ITEMS: 'global.preference.calcul.price.import.ca.items'
  }
;

export const SECURITY = {
  FEATURES: {

    GESTIONFOURNISSEURS: 'GestionFournisseur',
    GESTIONPRODUITS: 'valid',
    GESTIONMENUS: 'GestionMenu',
    GESTIONMARCHES: 'valid',
    // CFS = commandes fournisseurs
    GESTION_CFS: 'valid',
    GESTION_PRODUCTION: 'valid',
    CLIENTS: 'valid',
    GESTION_CLIENTS: 'valid',
    GESTION_LISTES_BESOINS: 'valid',
    GESTION_PLC: 'valid',
    GESTION_STOCKS: 'valid',
    MONBUREAU: 'valid',
    ADMINISTRATION: 'valid',
    GESTION_UNITE_DE_PRODUCTION: 'valid',
    GESTION_IDISTRI: 'valid',
    GESTION_PMS: 'valid',
    PORTAIL_PLC: 'valid',
    GRAPHQL: 'valid',
    GESTION_ADMINISTRATION: 'valid'
  },

  ROLES: {
    ROLE_SUPER_ADMIN:'ROLE_SUPER_ADMIN',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_UTILISATEUR: 'ROLE_UTILISATEUR',
    ROLE_DEVELOPER: 'ROLE_DEVELOPPEUR',
    ROLE_DIET: 'ROLE_DIET',
    ROLE_COMMANDES_FOURNISSEURS: 'ROLE_COMMANDES_FOURNISSEURS',
    ROLE_PRODUCTION: 'ROLE_PRODUCTION',
    ROLE_BESOINS: 'ROLE_BESOINS',
    ROLE_CLIENTS: 'ROLE_CLIENTS',
    ROLE_GESTION_CLIENTS: 'ROLE_GESTION_CLIENTS',
    ROLE_STOCKS: 'ROLE_STOCKS',
    ROLE_FACTURATION: 'ROLE_FACTURATION',
    ROLE_MARCHE: 'ROLE_MARCHE',
    ROLE_PLC: 'ROLE_PLC',
    ROLE_PRODUITS_IPROD: 'ROLE_PRODUITS_IPROD',
    ROLE_PRODUITS_IAPPRO: 'ROLE_PRODUITS_IAPPRO',
    ROLE_FOURNISSEUR: 'ROLE_FOURNISSEUR',
    ROLE_UNITE_DE_PRODUCTION: 'ROLE_UNITE_DE_PRODUCTION',
    ROLE_UNITE_DE_PRODUCTION_LIVRAISONS: 'ROLE_UNITE_DE_PRODUCTION_LIVRAISONS',
    ROLE_UNITE_DE_PRODUCTION_FABTRICATIONS: 'ROLE_UNITE_DE_PRODUCTION_FABRICATIONS',
    ROLE_UNITE_DE_PRODUCTION_STOCKS: 'ROLE_UNITE_DE_PRODUCTION_STOCKS',
    ROLE_UNITE_DE_PRODUCTION_ARTICLES_PREFERES: 'ROLE_UNITE_DE_PRODUCTION_ARTICLES_PREFERES',
    ROLE_UNITE_DE_PRODUCTION_DENREES_INTERDITES: 'ROLE_UNITE_DE_PRODUCTION_DENREES_INTERDITES',
    ROLE_UNITE_DE_PRODUCTION_EQUIPES: 'ROLE_UNITE_DE_PRODUCTION_EQUIPES',
    ROLE_IDISTRI: 'ROLE_IDISTRI',
    ROLE_IAPPRO_ADMIN:'ROLE_IAPPRO_ADMIN',
    ROLE_PMS: 'ROLE_PMS',
    ROLE_PORTAIL_ADMIN:'ROLE_PORTAIL_ADMIN',
    ROLE_PORTAIL_USER:'ROLE_PORTAIL_USER',
    ROLE_READ_GRAPHQL: 'ROLE_READ_GRAPHQL',
    ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD: 'ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD',
    ROLE_COMMANDES_FOURNISSEURS_FACTURE: 'ROLE_COMMANDES_FOURNISSEURS_FACTURE'
  }
};

export const HAS_GESTIONPRODUITS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UTILISATEUR,
  licence: SECURITY.FEATURES.GESTIONPRODUITS
};

export const HAS_IAPPRO_ADMIN: UserHasRight = {
  role: SECURITY.ROLES.ROLE_IAPPRO_ADMIN,
  licence: SECURITY.FEATURES.ADMINISTRATION
};

export const HAS_GESTIONPRODUITS_IPROD: UserHasRight = {
  role: SECURITY.ROLES.ROLE_PRODUITS_IPROD,
  licence: SECURITY.FEATURES.GESTIONPRODUITS
};

export const HAS_GESTIONPRODUITS_IAPPRO: UserHasRight = {
  role: SECURITY.ROLES.ROLE_PRODUITS_IAPPRO,
  licence: SECURITY.FEATURES.GESTIONPRODUITS
};

export const HAS_GESTIONFOURNISSEUR: UserHasRight = {
  role: SECURITY.ROLES.ROLE_FOURNISSEUR,
  licence: SECURITY.FEATURES.GESTIONFOURNISSEURS
};

export const HAS_GESTION_UDP: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_LIV: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_LIVRAISONS,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_FAB: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_FABTRICATIONS,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_STOCKS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_STOCKS,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_ARTICLES_PREFERES: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_ARTICLES_PREFERES,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_DENREES_INTERDITES: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_DENREES_INTERDITES,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_UDP_EQUIPES: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION_EQUIPES,
  licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION
};

export const HAS_GESTION_STOCKS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_STOCKS,
  licence: SECURITY.FEATURES.GESTION_STOCKS
};

export const HAS_GESTIONMARCHE: UserHasRight = {
  role: SECURITY.ROLES.ROLE_MARCHE,
  licence: SECURITY.FEATURES.GESTIONMARCHES
};

export const HAS_GESTIONMENU: UserHasRight = {
  role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS
};

export const HAS_GESTIONCLIENTS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_CLIENTS, licence: SECURITY.FEATURES.CLIENTS
};

export const HAS_GESTIONBESOIN: UserHasRight = {
  role: SECURITY.ROLES.ROLE_BESOINS,
  licence: SECURITY.FEATURES.GESTION_LISTES_BESOINS
};

export const HAS_STOCKS: UserHasRight = {role: SECURITY.ROLES.ROLE_STOCKS, licence: SECURITY.FEATURES.GESTION_CFS};

export const HAS_FACTURATION: UserHasRight = {
  role: SECURITY.ROLES.ROLE_FACTURATION,
  licence: SECURITY.FEATURES.GESTION_CFS
};

export const HAS_MONBUREAU: UserHasRight = {
  role: SECURITY.ROLES.ROLE_UTILISATEUR,
  licence: SECURITY.FEATURES.MONBUREAU
};

export const HAS_ADMINISTRATION: UserHasRight = {
  role: SECURITY.ROLES.ROLE_ADMIN,
  licence: SECURITY.FEATURES.ADMINISTRATION
};

export const HAS_GESTIONCONTRATS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_DIET,
  licence: SECURITY.FEATURES.GESTIONMENUS
};

export const HAS_GESTIONPRODUCTION: UserHasRight = {
  role: SECURITY.ROLES.ROLE_PRODUCTION,
  licence: SECURITY.FEATURES.GESTION_PRODUCTION
};


export const HAS_GESTIONGEMRCN: UserHasRight = {
  role: SECURITY.ROLES.ROLE_DIET,
  licence: SECURITY.FEATURES.GESTIONMENUS
};
export const HAS_GESTIONCOMMANDES: UserHasRight = {
  role: SECURITY.ROLES.ROLE_COMMANDES_FOURNISSEURS,
  licence: SECURITY.FEATURES.GESTION_CFS
};
export const HAS_ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD: UserHasRight = {
  role: SECURITY.ROLES.ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD,
  licence: SECURITY.FEATURES.GESTION_CFS
}

export const HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES: UserHasRight = {
  role: SECURITY.ROLES.ROLE_COMMANDES_FOURNISSEURS_FACTURE,
  licence: SECURITY.FEATURES.GESTION_CFS
}

export const HAS_GESTION_PLC: UserHasRight = {
  role: SECURITY.ROLES.ROLE_PLC,
  licence: SECURITY.FEATURES.GESTION_PLC
};

export const HAS_GESTION_IDISTRI: UserHasRight = {
  role: SECURITY.ROLES.ROLE_IDISTRI,
  licence: SECURITY.FEATURES.GESTION_IDISTRI
};

export const HAS_PMS: UserHasRight = {
  role: SECURITY.ROLES.ROLE_PMS,
  licence: SECURITY.FEATURES.GESTION_PMS
};

export const HAS_PORTAIL_ADMIN :UserHasRight = {
  role: SECURITY.ROLES.ROLE_PORTAIL_ADMIN,
  licence: SECURITY.FEATURES.PORTAIL_PLC
};

export const HAS_PORTAIL_USER :UserHasRight = {
  role: SECURITY.ROLES.ROLE_PORTAIL_USER,
  licence: SECURITY.FEATURES.PORTAIL_PLC
};

export const HAS_READ_GRAPHQL: UserHasRight = {
  role: SECURITY.ROLES.ROLE_READ_GRAPHQL,
  licence: SECURITY.FEATURES.GRAPHQL
}

export const HAS_GESTION_ADMINISTRATION: UserHasRight = {
  role: SECURITY.ROLES.ROLE_SUPER_ADMIN,
  licence: SECURITY.FEATURES.GESTION_ADMINISTRATION
}


export const HEADERS_JSON = new Headers();
HEADERS_JSON.append('Accept', 'application/json');


export const LOCAL_STORE = {
  GESTIONMENUS: {
    GEMRCN: {
      CONTRATSMENUSCONVIVES: 'gestionmenus.gemrcn.contratsmenusconvives'
    },
    STARTDATE: 'gestionmenus.startdate',
    CONTRATMENU: 'gestionmenus.contratmenu',
    CONTRATSMENUSCONVIVES: 'gestionmenus.contratsmenusconvives',
    IMPRESSION: {
      CALCULMATIERES: {
        FORMATFICHIER: 'gestionmenus.impression.calculmatieres.formatfichier',
        CONTRATSMENUSCONVIVES: 'gestionmenus.impression.calculmatieres.contratsmenusconvives',
        DEBUT: 'gestionmenus.impression.calculmatieres.debut',
        FIN: 'gestionmenus.impression.calculmatieres.fin',
      }
    },
    RECHERCHE: {
      ENABLEPRICE: 'gestionmenus.recherche.enableprice',
      PRIXMIN: 'gestionmenus.recherche.prixmin',
      PRIXMAX: 'gestionmenus.recherche.prixmax',
      fulltextproduitdecli: 'gestionmenus.recherche.fullTextProduitDecli',
      idsmenuconvivedeclinaison: 'gestionmenus.recherche.idsMenuConviveDeclinaison',
      idsfamilleproduit: 'gestionmenus.recherche.idsFamilleProduit',
      idsfamillegemrcn: 'gestionmenus.recherche.idsFamilleGemrcn',
      IDSDECLINAISON: 'gestionmenus.recherche.idsDeclinaison'
    }
  },
  GESTIONPRODUITS: {
    PRODUITFABRIQUE: {
      FICHETECHNIQUE: {
        RECHERCHE: 'gestionproduits.produitfabrique.fichetechnique.recherche'
      }
    }
  }
}

export const SESSION_STORE = {
  GESTIONPRODUIT: {
    PRODUIT: 'yoProduit',
    PRODUITDECLI: 'yoProduitDeclinaison',
    PRODUITNOMENCLATURE_LIST: 'yoProduitNomenclatureList'
  },
  GESTIONFOURNISSEUR: {
    FOURNISSEUR: 'yoSecteurFournisseur'
  }

};


export const FORMATS_IMPRESSION: FormatImpression[] = [
  {libelle: 'A0 (841 x 1189 mm)', codeFormat: 'A0'},
  {libelle: 'A1 (594 x 841 mm)', codeFormat: 'A1'},
  {libelle: 'A2 (420 x 594 mm)', codeFormat: 'A2'},
  {libelle: 'A3 (297 x 420 mm)', codeFormat: 'A3'},
  {libelle: 'A4 (210 x 297 mm)', codeFormat: 'A4'},
  {libelle: 'A5 (148 x 210 mm)', codeFormat: 'A5'}
];

export const MIME_TYPE = {
  OCTETSTREAM: 'application/octet-stream',
  PDF: 'application/pdf',
  TEXT_CSV_UTF8: 'text/csv;charset=utf-8'
}

export const FORMATS_FICHIERS_KEYS = {
  EXCEL: 'EXCEL',
  WORD: 'WORD',
  PDF: 'PDF'
}

export const FORMATS_FICHIER = new Map();
FORMATS_FICHIER.set(FORMATS_FICHIERS_KEYS.EXCEL, {
  label: 'EXCEL',
  icon: 'fa fa-file-excel-o'
});
FORMATS_FICHIER.set(FORMATS_FICHIERS_KEYS.PDF, {
  label: 'PDF',
  icon: 'fa fa-file-pdf-o'
});
FORMATS_FICHIER.set(FORMATS_FICHIERS_KEYS.WORD, {
  label: 'WORD',
  icon: 'fa fa-file-word-o'
});

export const FORMATS_ORIENTATION: FormatOrientation[] = [
  {libelle: 'Portrait', orientation: 'portrait'},
  {libelle: 'Paysage', orientation: 'paysage'}
];

export const DIALOG = {
  TITLE: {
    SUPPRESSION: 'Supprimer'
  },
  QUESTION: {
    CONFIRM_SUPPRRESION: 'Êtes vous sûr ?'
  }
};


export const GEMRCN_OPERATORS = {
  MIN: {value: '>', viewValue: 'mini'},
  MAX: {value: '<', viewValue: 'maxi'},
  EGAL: {value: '=', viewValue: 'égal'},
  LIBRE: {value: 'l', viewValue: 'libre'}
};

// FRONT PRIMARY ROUTES
export const FP_ROUTES = {
  GCO: 'gestioncontrats',
  GESTION_LISTES_BESOINS: 'gestion-listes-besoins',
  GESTION_COMMANDES_FOURNISSEURS: 'gestion-commandes-fournisseurs',
  GESTION_CLIENTS: 'gestion-clients',
  GESTION_PRODUCTION: 'gestion-production',
  GESTION_PROCESSUS: 'gestion-processus',
  GESTION_PLC: 'gestion-plc',
  GESTION_STOCKS: 'gestion-stocks',
  GESTION_DENREES_INTERDITES: 'gestion-denrees-interdites',
  GBESOINS: 'gestion-besoins',
  GESTION_MARCHE: 'gestion-marche',
  GESTION_CONDITIONNEMENTS: 'gestion-conditionnements',
  GUP: 'gestion-unitesproduction',
  GME: 'gestionmenus',
  ADM: 'administration',
  GGE: 'gestiongemrcn',
  GPR: 'gestionproduits',
  GFO: 'gestion-fournisseurs',
  GGA: 'gestion-groupe-achat',
  MBU: 'monbureau',
  FEA: 'features',
  SIT: 'sites',
  ENV: 'environnements',
  UTI: 'utilisateurs',
  UTI_CO: 'utilisateursconnexions',
  ALL: 'allergenes',
  APPELLATIONS: 'appellations',
  ANUT: 'apports-nutritionnels',
  CON: 'convives',
  DCR: 'decoupages-repas',
  FAMGEMRCN: 'familles-gemrcn',
  FAMPRODUITS: 'familles-produit',
  MARCHES: 'marches',
  FAMPLANALIM: 'familles-plans-alimentaires',
  GROUPNUTRI: 'groupes-nutritionnels',
  TYPES_PRODUIT: 'types-produit',
  TYPES_FABRICATION: 'types-fabrication',
  TYPES_APPRO: 'types-appro',
  TYPES_SORTIE: 'types-sortie',
  DECLINAISONS: 'declinaisons',
  REPAS: 'repas',
  UDM: 'unites-de-mesures',
  ZDS: 'zones-de-stockage',
  JSE: 'jours-semaine',
  ZDP: 'ateliers',
  EQU: 'equipes',
  SECTEURS: 'secteurs',
  TAXES: 'taxes',
  PROFILS: 'profils',
  MODELE_PLAT: 'modele-plat',
  FACTURES: 'factures'
};

// SECONDARY ROUTE SYSTEM NAME (sidenav)
export const OUTLET_SECONDARY = 'secondary';

export const OUTLET_TERTIARY = 'tertiary';

// FRONT SECONDARY ROUTES
export const FS_ROUTES = {

  // gestioncontrats-contratmenuconvive
  GCO_CMC: `${FP_ROUTES.GCO}-contratmenuconvive`,

  // administration-site-ficheidentite
  ADM_SITE_FIC: `${FP_ROUTES.ADM}-site-ficheidentite`,

  // administration-environnement-ficheidentite
  ADM_ENVIRONNEMENT_FIC: `${FP_ROUTES.ADM}-environnement-ficheidentite`,

  // administration-sidebar-environnement-plc
  ADM_ENVIRONNEMENT_PLC_FIC: `${FP_ROUTES.ADM}-sidebar-environnement-plc`,

  // administration-utilisateur-ficheidentite
  ADM_UTILISATEUR_FIC: `${FP_ROUTES.ADM}-utilisateur-ficheidentite`,

  // gestiongemrcn-modelenutri
  GGE_MODELENUTRI: `${FP_ROUTES.GGE}-modelenutri`,

  // gestionproduits-produit
  GPR_PRODUIT: `${FP_ROUTES.GPR}-produit`,

  // gestionmenus-settings
  GME_SETTINGS: `${FP_ROUTES.GME}-settings`,

  // gestionmenus-infosMenusCompos
  GME_INFOS_MENU: `${FP_ROUTES.GME}-infosMenu`,

  // gestionmenus-duplicationMenu
  GME_DUPLICATION_MENU: `${FP_ROUTES.GME}-duplicationMenu`,

  // gestionmenus-gemrcn
  GME_GEMRCN: `${FP_ROUTES.GME}-gemrcn`,

  // gestionmenus-couts
  GME_COUTS: `${FP_ROUTES.GME}-couts`,

  GME_EGALIM: `${FP_ROUTES.GME}-egalim`,

  // gestion-unitesproduction
  GUP_UPRO: `${FP_ROUTES.GUP}-uniteDeProduction`,

  // gestion-fournisseurs
  GFO_FOU: `${FP_ROUTES.GFO}-fournisseur`,

  // gestion-groupe-achat
  GGA_GRA: `groupe-achat`,

  // gestion-listes-besoins-fournisseurs
  GESTION_LISTES_BESOINS_FOU: `${FP_ROUTES.GESTION_LISTES_BESOINS}-fournisseur`,

  // gestion-conditionnements-modele-plat
  GESTION_CONDITIONNEMENTS_MODELE_PLAT: `${FP_ROUTES.GESTION_CONDITIONNEMENTS}-modele-plat`,

  // gestion-conditionnements-modele-plc
  GESTION_CONDITIONNEMENTS_MODELE_PLC: `${FP_ROUTES.GESTION_CONDITIONNEMENTS}-modele-plc`,

  // gestion-preparation-conditionnements
  GESTION_PREPARATION_CONDITIONNEMENTS: `${FP_ROUTES.GESTION_CONDITIONNEMENTS}-preparation`,

  FACTURES_FOURNISSEURS: 'fournisseurs'
}

/**
 * Front Tertiary routes
 * @type {{}}
 */
export const FT_ROUTES = {
  GFO_FOU_FIL: `${FP_ROUTES.GFO}-fournisseur/filiales`
};

/**
 * Séparateur pour les fichiers .csv
 * @type {string}
 */
export const EXCEL_SEPARATOR = ';';


// -----------------------------------------------------
// constantes pour le WORKFLOW commandes fournisseurs
// -----------------------------------------------------

export const LB_MAP_REVISION = new Map<number, any>();
LB_MAP_REVISION.set(1, {label: 'a débuté'});
LB_MAP_REVISION.set(2, {label: 'a modifié'});
LB_MAP_REVISION.set(3, {label: 'a validé'});
LB_MAP_REVISION.set(4, {label: 'a refusé'});
LB_MAP_REVISION.set(5, {label: 'a terminé'});
LB_MAP_REVISION.set(6, {label: 'etape en ERREUR'});

export const WORKFLOW_REVISION = {
  DEBUTE: 'DEBUTE',
  MODIFIE: 'MODIFIE',
  TERMINE: 'TERMINE',
  VALIDE: 'VALIDE',
  REFUSE: 'REFUSE',
  EN_ERREUR: 'en_erreur',
};


/**
 * Prefix du code associé à l'image d'un produit.
 * @type {string}
 */
export const PREFIX_CODE_DOCUMENT_IMAGE_PRODUIT = 'ImageProduit';

/**
 * Statut d'un bon de commande fournisseur
 */
export const BCF_STATUT = {
  PROPOSITION_COMMANDE: 'PROPOSITION_COMMANDE',
  COMMANDE_REFUSEE: 'COMMANDE_REFUSEE',
  COMMANDE_A_VALIDER: 'COMMANDE_A_VALIDER',
  COMMANDE_A_RECEPTIONNER: 'COMMANDE_A_RECEPTIONNER',
  COMMANDE_RECEPTIONNEE: 'COMMANDE_RECEPTIONNEE',
  COMMANDE_FACTUREE: 'COMMANDE_FACTUREE',
}

/**
 * Statut d'un envoi de mail de bon de commande fournisseur
 */
export const BCF_MAIL_STATUT = {
  EN_COURS_ENVOI: 1,
  ENVOYE: 2,
  LU: 3,
  EN_ERREUR: 4,
}


export const WORKFLOW_TASKS = {
  PRODUCTION_LISTE_PLATS: 'production-liste-plats',
  SELECTION_MENUS: 'selection-menus',
  SELECTION_PLCS: 'selection-plcs',
  BESOINS: 'besoins',
  COMMANDES_FOURNISSEURS: 'commandes-fournisseurs',
  SORTIES_PRODUCTION: 'sorties-production',
  CALCUL_PRODUCTION: 'calcul-production'
}

/**
 * utilisé notamment pour les jours semaine
 */
export class JourSemaine {
  value: number;
  viewValue: string;


}

export const TYPES_CODES = {
  NUMERO_BON_RECEPTION: 'NUMERO_BON_RECEPTION',
  NUMERO_TRACA_ARTICLE: 'NUMERO_TRACA_ARTICLE',
  CODE_API_ARTICLE: 'CODE_API_ARTICLE',
  CODE_PRODUIT_DECLINAISON: 'CODE_PRODUIT_DECLINAISON',
  CODE_PRODUIT: 'CODE_PRODUIT',

}

export enum MODE_RECEPTION_STATUT {
  EN_COURS = 1,
  RECEPTION_PARTIELLE = 2,
  TERMINE = 3

};

export const RECEPTION_STATUT = {
  NOUVEAU: 'NOUVEAU',
  EN_COURS: 'EN_COURS',
  RECEPTION_PARTIELLE: 'RECEPTION_PARTIELLE',
  TERMINE: 'TERMINE'

};

export const STOCKS_FEATURES = {
  INVENTAIRE: 'inventaire',
  MATIERES_PREMIERES: 'matieres-premieres',
  MOUVEMENTS: 'mouvements'
};


export const MSG_SEVERITY = {
  SUCCESS: 'success',
  INFO: 'info',
  /**
   * @deprecated
   */
  WARN: 'warn', // A supprimer quand p-toast sera complétement supprimé
  ERROR: 'error',
  WARNING: 'warning'
};

export const MSG_KEY = {
  ROOT: 'root',
  ROOT_ERROR: 'root_error',
  SIDEBAR: 'sidebar',
  SIDEBAR_CENTER: 'sidebar_center',
  BC_A_RECEPTIONNER: 'bc-a-receptionner',
  BC_PROPOSITIONS: 'bc-propositions',
  MODELE_NUTRI: 'modelenutri',
  ACTION_PROFIL: 'actionProfil',
  PC: 'pc',
  BESOIN: 'besoin',
  COMPTE_RENDU: 'compteRendu',
  ACTION_MAJ_CATALOGUE_ACHAT: 'actionMajCatalogueAchat',
  AJOUT_COMPOSANT: 'ajoutcomposant',
  NEXT_STEP: 'nextStep',
  FICHE_TECHNIQUE: 'fichetechnique',
  DUPLICATION: 'duplication',
  GENERIC_PN: 'generic-pn',
  DIALOG_BON_RECPTION_COMMANDE: 'bon-reception-lignes',
  DIALOG_EDIT_ARTICLE: 'dialog-edit-article',
  PLAN_ALIMENTAIRE: 'plan-alimentaire',
  PLAN_PRODUCTION: 'pp',
  DIALOG_BON_RECEPTION: 'bc-edit-fournisseur',
  DIALOG_ARTICLE_RECEPTION: 'dialog-article-reception',
  DIALOG_BON_RECEPTION_FROM: 'dialog-bon-reception-lignes-form',
  DIALOG_CREATE_BON_RECEPTION: 'dialog-create-bon-reception'
}

export const REGLES_PREFERES = {
  MOINS_CHER: '1_MOINS_CHER',
  FILIALES: '2_FILIALES',
  PAR_DEFAUT: '0_PAR_DEFAUT'
};

/**
 * Message expliquant à l'utilisateur connecté pourquoi il ne peut créer d'objet.
 */
export const MESSAGE_CREATION_OBJET_IMPOSSIBLE = 'Création impossible : vous ne possèdez aucun site local';

export const MENUS_TYPES_LIGNES = {
  NORMAL: 1,
  COUT: 3,
  ANALYSE_COUT: 2,
  ANALYSE_NUTRI: 4,
  SEPARATEUR: 5,


};


export const STATUTS_MENUS = {
  CREATION_MENUS: 'CREATION_MENUS',
  SAISIE_EFFECTIFS: 'SAISIE_EFFECTIFS'
};

/**
 * options d'écrasement des menus lors de la validation des menus
 */
export const OEM = {
  AUCUN_ECRASEMENT: 0,
  ECRASER_MENUS_SANS_MODIFICATION_MANUELLE: 1,
  ECRASER_MENUS_AVEC_MODIFICATIONS_MANUELLES: 2,
}

/**
 * options d'écrasement des taux de prise menu lors de la validation des menus
 */
export const OETDP = {
  AUCUN_ECRASEMENT: 0,
  ECRASER: 1,
}


export const CHOIX_GEMRCN = {
  DIRIGE: 1,
  MULTIPLE: 2
}


export enum PREDICAT_OPERATOR {
  In = 'in',
  Equals = 'equals',
  Contains = 'contains',
  GreaterThan = 'gt',
  GreaterThanEquals = 'gte',
  LessThanEquals = 'lte',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull'
}

export enum PREDICAT_TYPE {
  Integer = 'integer',
  Boolean = 'boolean',
  String = 'string',
  Date = 'date'
}

export enum PREDICAT_DIR {
  Ascendant = 'asc',
  Descendant = 'desc'
}

export enum PREDICAT_VALUE {
  True = 'true',
  False = 'false'
}


export enum LOTS_PERIMES {
  TOUS = 'T',
  DENREE_SELECTIONNEE = 'S'
}

export enum DUPLICATION_ECRASER_MENUS {
  AUCUN = 'AUCUN',
  ECRASER_SI_PLAT_EXISTANT = 'PLAT_EXISTANT',
  ECRASER_TOUT = 'TOUT'

}

export enum ETAPE_CALCUL_ARTICLES_PREFERES {
  MAJ_PRIX_KILO = 'MAJ_PRIX_KILO',
  SELECTION_PREFERE = 'SELECTION_PREFERE',
  MAJ_PREFERE = 'MAJ_PREFERE',
  PRIX_PLAT = 'PRIX_PLAT'

}

export enum MODE_PROGRESS_BAR {
  INDETERMINATE = 'indeterminate',
  DETERMINATE = 'determinate'
}

export enum TYPE_PRESTATION_ID {
  Menu = 1,
  PriseDeCommande = 2
}

export enum LISTEBESOINS_CHOIX_VUE {
  ARTICLES = 1,
  DENREES = 2
}

export enum MENUS_PLANNING_CHOOSER {
  CONTRAT = 'contrat',
  PRESTATION = 'prestation',
  REGIME = 'regime',

}

export enum UI_COLORS {
  MODIFICATION_MANUELLE = '#ffe5ec',
  EDITABLE = '#DFF6E6',
  INFO = '#fff5db',
  MANDATORY = '#ffaa97',
  BG_COL_HEADER = '#F4F4F4',
  COLOR_COL_HEADER = '#333333',
  CHECKED = '#78D58E',
  NOT_CHECKED = '#ffa7af',
  INHERIT = 'inherit'
}

export enum VUE_SORTIES_POUR_PRODUCTION {
  DENREE = 'DENREE',
  STOCK = 'STOCK'
}

export enum STATUT_SORTIE_POUR_PRODUCTION {
  PROPOSITION_DE_SORTIE = 'PROPOSITION_DE_SORTIE',
  SORTIE_VALIDEE = 'SORTIE_VALIDEE',
  SORTIE_RUPTURE = 'SORTIE_RUPTURE'
}


export enum MSG_POSITION {
  TOP_RIRGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  TOP_CENTER = 'top-center',
  BOTTOM_CENTER = 'bottom-center',
  CENTER = 'center'
}


export enum TYPE_EFFECTIF {
  previsionnelle = 'effPrev',
  fabrication = 'effFabrication',
  facturation = 'effFacture'
}

export enum TYPE_EFFECTIF_IDS {
  previsionnelle = 1,
  fabrication = 2,
  facturation = 3
}

export enum TYPE_RESULT_VIEW_GRAPHQL {
  pretty_json = 'pretty_json',
  plain_json = 'plain_json',
  table = 'table'
}

export enum TYPE_VERROU {
  udpCa = 'unites_de_production_catalogue_achat',
  udpPd = 'unites_de_production_produit_declinaison'
}

export enum SEARCH_PLATS_TYPE_DIALOG {
  MENUS = 1,
  PLC = 2
}


export const PRIMENG_TRANSLATIONS = {
  startsWith: 'commence par',
  contains: 'contient',
  notContains: 'ne contient pas',
  endsWith: 'fini par',
  equals: 'égal',
  notEquals: 'différent',
  noFilter: 'pas de filtre',
  lt: 'inférieur à',
  lte: 'supérieur ou égal à',
  gt: 'supérieur à',
  gte: 'supérieur ou égal à',
  is: 'est',
  isNot: 'n\'est pas',
  before: 'avant',
  after: 'après',
  clear: 'effacer',
  apply: 'appliquer',
  matchAll: 'tous',
  matchAny: 'n\'importe lequel',
  addRule: 'ajouter une règle',
  removeRule: 'enlever une règle',
  accept: 'oui',
  reject: 'non',
  choose: 'choisir',
  upload: 'envoyer',
  cancel: 'annuler',
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'Aujourd\'hui',
  weekHeader: 'S'
};


export enum SENS_JOUR_DECALAGE {
  PLUS = '+',
  MOINS = '-'
}

export enum TYPE_LIST_UDP {
  OnlyReferent = 'OnlyReferent',
  OnlyLocal = 'OnlyLocal',
  ReferentAndLocal = 'ReferentAndLocal',
}

export enum TYPE_UNITE_DE_MESURE {
  uniteTechnique,
  uniteDeCommande,
  uniteDeStockage,
  uniteDeFacturation
}

export const UNITE_BLOCAGE_CONFIGURATION_PORTAIL = [
  {libelle:'semaine', value:'week'},
  {libelle:'jour', value:'day'}
];

export const HELP_FOLDERS = {
  CLIENTS: 'clients',
  COMMANDES_FOURNISSEURS: 'commandes-fournisseurs',
  CONDITIONNEMENT: 'conditionnement',
  CONTRATS: 'contrats',
  CONTRATS_MENUS: 'contrats/menus',
  CONTRATS_MENUS_CONVIVES: 'contrats/menus/convives',
  FOURNISSEURS: 'fournisseurs',
  FOURNISSEURS_ARTICLES: 'fournisseurs/articles',
  FOURNISSEURS_GROUPES_ACHATS: 'fournisseurs/groupe-achat',
  GEMRCN: 'gemrcn',
  LISTE_BESOINS: 'liste-besoins',
  MARCHES: 'marches',
  MENUS: 'menus',
  PLANS_PRODUCTION: 'plans-production',
  PMS: 'pms',
  PRODUITS: 'produits',
  REFERENTIEL: 'referentiel',
  ORGANISATIONS_TOURNEES: 'organisations-tournees',
  STOCKS: 'stocks',
  UNITE_DE_PRODUCTION: 'unite-de-production',
  FACTURATION: 'facturation'
}
