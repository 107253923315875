import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {combineLatest, Observable} from "rxjs";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {
  McpConditionnementsVariantesService
} from "../../../core/services/conditionnements/mcp-conditionnements-variantes.service";
import {AtelierDTO} from "../../../core/dtos/atelier-d-t-o";
import {EquipeDTO} from "../../../core/dtos/equipe-dto";
import {TacheDTO} from "../../../core/dtos/tache-dto";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-12',
  templateUrl: './wizard-configuration-step-12.component.html',
  styleUrls: ['./wizard-configuration-step-12.component.scss']
})
export class WizardConfigurationStepTwelveComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  modeSelected: string = 'AUTO';

  ateliers: AtelierDTO[] = [];

  equipes: EquipeDTO[] = [];

  taches: TacheDTO[] = [];

  placeHolderAtelier: string;

  placeHolderEquipe: string;

  placeHolderTache: string;

  valuesByTache: any = {};

  constructor(private auth2Svc: Auth2Service,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private mcpcvSvc: McpConditionnementsVariantesService,
              private graphQlSvc: GraphQLService,
              private wizardGlobalSvc: WizardService
             ) {
    this.placeHolderAtelier = 'Veuillez sélectionner un atelier';
    this.placeHolderEquipe = 'Veuillez sélectionner une équipe';
    this.placeHolderTache = 'Veuillez sélectionner une tâche';
    this.currentStep = this.wizardSvc.STEP_CHECK_CREWS;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initData();
  }

  placeHolder = (type: string, tacheLibelle: string): string => {
    if (type === 'atelier') return `Veuillez sélectionner un atelier pour le type de conditionnement ${tacheLibelle}`;
    if (type === 'equipe') return `Veuillez sélectionner une équipe pour le type de conditionnement ${tacheLibelle}`;
    return '';
  }

  initData = (): void => {
    const dataStepTwo: any = this.wizardSvc.getDataStepTwo();
    const idModelePlat: number = dataStepTwo.id;

    const ateliersList$ = this.getAteliers();
    const equipes$ = this.getEquipes();
    const combine$ = combineLatest([
      this.mcpcvSvc.getAllByIdMcp(idModelePlat),
      ateliersList$,
      equipes$
    ]);
    combine$.subscribe((results) => {
      this.taches = results[0].resultList.map(it => it.tache).filter(t => t.typeTacheCode === 'CONDI');
      this.taches = [...new Map(this.taches.map((item: TacheDTO) => [item["id"], item])).values()] as TacheDTO[];
      this.ateliers = results[1].allAteliers;
      this.equipes = results[2].allEquipes;
      this.taches.forEach(tache => this.valuesByTache[tache.id] = { idAtelier: null, idEquipe: null });
    });
  }

  private getEquipes = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allEquipes(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `)
  }

  private getAteliers = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allAteliers(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `)
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      valuesByTache: this.valuesByTache,
      idMcPlc: this.wizardSvc.getDataStepEight()?.id,
      modeSelected: this.modeSelected
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
