import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-8',
  templateUrl: './wizard-configuration-step-8.component.html',
  styleUrls: ['./wizard-configuration-step-8.component.scss']
})
export class WizardConfigurationStepEightComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  idSelectedSite: number;

  sitePlaceholder: string = 'Site gestionnaire des modèles de point de livraison';

  dataSource: any;

  dataAlreadyChoosen: any;

  placeholderLabelModelePlc: string = "Libellé du modèle de point de livraison";

  labelModelPlc: string;

  constructor(private auth2Svc: Auth2Service,
              private udpSvc: UnitesDeProductionService,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_TARGET_MODEL_PLC;
  }

  ngOnInit() {
    this.initDataAlreadyChoosenByUser();
    this.findAllLocalSites();
  }

  ngOnDestroy() {
  }

  initDataAlreadyChoosenByUser = (): void => {
    this.dataAlreadyChoosen = this.wizardSvc.getDataStepEight();
    if (this.dataAlreadyChoosen?.idSelectedSite)
      this.idSelectedSite = this.dataAlreadyChoosen.idSelectedSite;
    if (this.dataAlreadyChoosen?.labelModelPlc)
      this.labelModelPlc = this.dataAlreadyChoosen.labelModelPlc;
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep, idSelectedSite: this.idSelectedSite, labelModelPlc: this.labelModelPlc });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep, id: this.dataAlreadyChoosen?.id, idSelectedSite: this.idSelectedSite, labelModelPlc: this.labelModelPlc });
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  onChangeSite = ($event): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
