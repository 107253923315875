import {Injectable} from "@angular/core";
import {HttpService} from "../../technique/http.service";
import {Observable, Subject} from "rxjs";
import {ModeleImplementationMcpDto} from "../../../dtos/conditionnement/plat/modele-implementation-mcp-dto";
import {HttpParams} from "@angular/common/http";
import {BindPlatsMcpModel} from "../../../models/gestion-conditionnements/bind-plats-mcp-model";
import {ResponseWrapper} from "../../../suppliers/wrappers/response-wrapper";

export const URL_SAVE = `dolrest/conditionnements/implementation-modeles-conditionnements-plats/save`;
export const URL_SAVE_MULTIPLE = `dolrest/conditionnements/implementation-modeles-conditionnements-plats/save-multiple`;
export const URL_FIND_ALL = `dolrest/conditionnements/implementation-modeles-conditionnements-plats/list`;
export const URL_FIND_VISUALIZATION = `dolrest/conditionnements/implementation-modeles-conditionnements-plats/visualize/`;
export const URL_DELETE = `dolrest/conditionnements/implementation-modeles-conditionnements-plats/delete`;

@Injectable({
  providedIn: 'root'
})
export class ImplementationModelesConditionnementsPlatsService {

  private subjectOpenDialog = new Subject<ModeleImplementationMcpDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectModelSaved = new Subject<ImplementationModeleConditionnementPlatSavedSupplier>();
  modelSaved$ = this.subjectModelSaved.asObservable();

  private subjectBindPlatsDone = new Subject<Boolean>();
  bindPlatsDone$ = this.subjectBindPlatsDone.asObservable();

  constructor(private httpSvc: HttpService) {}

  announceOpenDialogEdit = (model: ModeleImplementationMcpDto) => {
    this.subjectOpenDialog.next(model);
  };

  announceModelSaved = (model: ModeleImplementationMcpDto, isUpdate: boolean) => {
    this.subjectModelSaved.next({ model, isUpdate } as ImplementationModeleConditionnementPlatSavedSupplier);
  };

  announceBindPlatsDone = () => {
    this.subjectBindPlatsDone.next(true);
  };

  /**
   * Sauvegarde une implémentation du modèle
   * @param modele
   */
  save = (modele: ModeleImplementationMcpDto) => this.httpSvc.post(URL_SAVE, modele);

  /**
   * Attribue en masse un ou plusieurs plats à
   * un modèle de conditionnement plat.
   * @param model
   */
  saveMultiple = (model: BindPlatsMcpModel) => this.httpSvc.post(URL_SAVE_MULTIPLE, model);

  /**
   * Récupération de toutes les implémentations MCP par produit
   * @param produitId Identifiant du produit
   */
  findAll = (produitIds: number[]): Observable<ResponseWrapper<ModeleImplementationMcpDto>> => this.httpSvc.get(`${URL_FIND_ALL}?idsProduit=${produitIds}`);

  /**
   * Récupération de la visualisation du calcul de l'implémentation du modèle MCP
   * @param produitId Produit
   * @param udpMcpId Unité de production - Modèle de conditionnement plat
   */
  findVisualization = (produitId: number, udpMcpId: number) => this.httpSvc.get(`${URL_FIND_VISUALIZATION}${produitId}/${udpMcpId}`);

  /**
   * Suppression d' implémentation(s) MCP
   */
  delete = (udpMcpIds: number[], idsUdp: number[], mcpId: number, produitId: number) => {
    let url = `${URL_DELETE}?produitId=${produitId}`;
    const params: HttpParams = new HttpParams();
    if (udpMcpIds && udpMcpIds.length)
      url += `&idsUdpMcp=${udpMcpIds}`;
    if (idsUdp)
      url += `&idsUdp=${idsUdp}`;
    if (mcpId)
      url += `&idModeleConditionnementPlat=${mcpId}`;
    params.set("produitId", `${produitId}`);
    return this.httpSvc.delete(url, null);
  };

  updatePoidsNetUniteConditionner = (weight: number, idProduit: number, idDeclinaison: number, idModelePlat: number) => {
    return this.httpSvc.post(`dolrest/conditionnements/implementation-modeles-conditionnements-plats/update-poids-net-unite-conditionner?weight=${weight}&idProduit=${idProduit}&idDeclinaison=${idDeclinaison}&idModelePlat=${idModelePlat}`, null);
  }

  saveMultipleModels = (modeles: ModeleImplementationMcpDto[]): Observable<ResponseWrapper<any>> => {
    console.log('modeles', modeles);
    return this.httpSvc.post(`dolrest/conditionnements/implementation-modeles-conditionnements-plats/save-multiple-implementations`, modeles);
  }

}

export interface ImplementationModeleConditionnementPlatSavedSupplier {
  isUpdate: boolean;
  model: ModeleImplementationMcpDto;
}
