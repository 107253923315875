import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionCommandesFournisseursRoutingModule} from './gestion-commandes-fournisseurs-routing.module';
import {GcfRootComponent} from './gcf-root/gcf-root.component';
import {TableauDeBordComponent} from './tableau-de-bord/tableau-de-bord.component';
import {BcAValiderComponent} from './bc/bc-a-valider/bc-a-valider.component';
import {BcRefuseesComponent} from './bc/bc-refusees/bc-refusees.component';
import {BcPropositionsComponent} from './bc/bc-propositions/bc-propositions.component';
import {BcFactureesComponent} from './bc/bc-facturees/bc-facturees.component';
import {SharedModule} from '../shared/shared.module';
import {BcComponent} from './bc/bc.component';
import {BcEditFournisseurComponent} from './bc/bc-edit-fournisseur/bc-edit-fournisseur.component';
import {BcAddArticleFournisseurComponent} from './bc/bc-edit-fournisseur/bc-add-article-fournisseur/bc-add-article-fournisseur.component';
import {CreatePropositionFournisseurComponent} from './bc/bc-propositions/create-proposition-fournisseur/create-proposition-fournisseur.component';
import {BcReceptionsComponent} from './bc/bc-receptions/bc-receptions.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {BcAReceptionnerComponent} from './bc/bc-a-receptionner/bc-a-receptionner.component';
import {DialogHistoMailsComponent} from './bc/dialog-histo-mails/dialog-histo-mails.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogArticlesReceptionGridComponent} from './bc/bc-a-receptionner/dialog-article-reception-grid/dialog-article-reception-grid.component';
import {
    DxBarGaugeModule, DxCalendarModule,
    DxChartModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxPieChartModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxTreeListModule,
    DxTreeViewModule
} from 'devextreme-angular';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {EditorModule} from 'primeng/editor';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {RadioButtonModule} from "primeng/radiobutton";
import {CreatePropositionDenreeComponent} from "./bc/bc-propositions/create-proposition-denree/create-proposition-denree.component";
import {AjoutDenreePropositionCommandeComponent} from "./bc/bc-propositions/create-proposition-denree/ajout-denree-proposition-commande/ajout-denree-proposition-commande.component";
import {ChipsModule} from "primeng/chips";
import {MergeBonCfComponent} from "./bc/merge-proposition/merge-bon-cf.component";
import {DuplicationBonCfComponent} from "./bc/duplication-proposition/duplication-bon-cf.component";

@NgModule({
    imports: [
        CommonModule,
        GestionCommandesFournisseursRoutingModule,
        SharedModule,
        GestionProcessusModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,

        CardModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        ChipsModule,
        SelectButtonModule,
        MultiSelectModule,
        EditorModule,
        ScrollPanelModule,
        DxTreeListModule,

        DxTemplateModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxLookupModule,
        DxTagBoxModule,
        RadioButtonModule,
        DxTextBoxModule,
        DxTreeViewModule,
        DxPopupModule,
        DxChartModule,
        DxPieChartModule,
        DxBarGaugeModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxToolbarModule,
        DxCalendarModule
    ],
  declarations: [
    GcfRootComponent,
    TableauDeBordComponent,
    BcAValiderComponent,
    BcRefuseesComponent,
    BcPropositionsComponent,
    BcFactureesComponent,
    BcComponent,
    BcEditFournisseurComponent,
    BcAddArticleFournisseurComponent,
    CreatePropositionFournisseurComponent,
    CreatePropositionDenreeComponent,
    AjoutDenreePropositionCommandeComponent,
    BcReceptionsComponent,
    BcAReceptionnerComponent,
    DialogHistoMailsComponent,
    DialogArticlesReceptionGridComponent,
    MergeBonCfComponent,
    DuplicationBonCfComponent
  ]
})
export class GestionCommandesFournisseursModule {
}
