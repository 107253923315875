import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-1',
  templateUrl: './wizard-configuration-step-1.component.html',
  styleUrls: ['./wizard-configuration-step-1.component.scss']
})
export class WizardConfigurationStepOneComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  idSelectedSite: number;

  sitePlaceholder: string = 'Site gestionnaire des modèles de plat';

  subUnitesDeProduction: Subscription;

  unitesDeProduction: UniteDeProductionDTO[] = [];

  unitesDeProductionSelected: number[] = [];

  dataSource: any;

  dataAlreadyChoosen: any;

  constructor(private auth2Svc: Auth2Service,
              private udpSvc: UnitesDeProductionService,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_TARGET_MODEL_DISH;
  }

  ngOnInit() {
    this.initDataAlreadyChoosenByUser();
    this.findAllLocalSites();
    this.initUnitesDeProductionLocauxSubscription();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subUnitesDeProduction);
  }

  initDataAlreadyChoosenByUser = (): void => {
    this.dataAlreadyChoosen = this.wizardSvc.getDataStepOne();
    if (this.dataAlreadyChoosen?.idSelectedSite)
      this.idSelectedSite = this.dataAlreadyChoosen.idSelectedSite;
  }

  selectAllUdp = (): void => {
    this.unitesDeProduction.forEach(udp => this.unitesDeProductionSelected.push(udp.id));
  }

  selectUdpChoosenByUser = (): void => {
    if (this.dataAlreadyChoosen?.unitesDeProductionSelected)
      this.unitesDeProductionSelected = this.unitesDeProduction.filter(udp => this.dataAlreadyChoosen.unitesDeProductionSelected.includes(udp.id)).map(udp => udp.id);
  }

  unselectAllUdp = (): void => {
    this.unitesDeProductionSelected = [];
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep, idSelectedSite: this.idSelectedSite, unitesDeProductionSelected: this.unitesDeProductionSelected });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep, idSelectedSite: this.idSelectedSite, unitesDeProductionSelected: this.unitesDeProductionSelected });
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  initUnitesDeProductionLocauxSubscription = (): void => {
    this.subUnitesDeProduction = this.udpSvc.findUnitesProductionByFilters({ enabled: true, onlyLocalSites: true })
      .subscribe(response => {
        this.unitesDeProduction = response.resultList;
        this.unitesDeProduction = this.unitesDeProduction.sort((a,b) => a.libelle.localeCompare(b.libelle));
        this.dataSource = new DataSource({
          store: new ArrayStore({
            key: 'id',
            data: this.unitesDeProduction
          })
        });
        if (!this.dataAlreadyChoosen?.unitesDeProductionSelected || !this.dataAlreadyChoosen?.unitesDeProductionSelected.length)
          this.selectAllUdp();
        else
          this.selectUdpChoosenByUser();
      });
  };

  onChangeSite = ($event): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  onChangeUniteProduction = ($event): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.unitesDeProductionSelected.filter(id => id === item.id).length)
          this.unitesDeProductionSelected.push(item.id);
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.unitesDeProductionSelected.filter(id => id === item.id).length)
          this.unitesDeProductionSelected = this.unitesDeProductionSelected.filter(id => id !== item.id);
      });
    }
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
