<dx-popup
  [width]="450"
  [height]="'auto'"
  [showTitle]="true"
  title="Notifications Datameal Online"
  [(visible)]="displayNotificationsInfos"
  [resizeEnabled]="true"
  [showCloseButton]="false"
>
  <div *dxTemplate="let data of 'content'">
    <p [innerHTML]="this.notificationsInfos"></p>
  </div>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="center"
    [options]="refreshPageOptions"
  >
  </dxi-toolbar-item>
</dx-popup>

<ng-container *ngIf="key === 'root'">
  <dx-toast id="toast-root" key="root" [width]="600" [displayTime]="timeMessage" [closeOnClick]="false" [(visible)]="isVisible" [type]="type">
    <div style="text-align: center" *dxTemplate="let data of 'content'">
      <a *ngIf="urlMessage !== null && urlMessage !== undefined" style="text-decoration: none; color: #fff" [href]="urlMessage"> {{ message }}</a>
      <span *ngIf="urlMessage === null || urlMessage === undefined">{{ message }}</span>
    </div>
  </dx-toast>
</ng-container>
<ng-container *ngIf="key === 'root_error'">
  <dx-toast id="root_error" key="root_error" [width]="600" [displayTime]="timeMessage" [closeOnClick]="false" [(visible)]="isVisible" [type]="MSG_SEVERITY.ERROR">
    <div style="text-align: center" *dxTemplate="let data of 'content'">
      <div class="d-flex flex-column">
        <strong>{{ message }}</strong>
      </div>
      <div class="d-flex flex-row">
        <div class="mr-auto">
        </div>
        <div>
          <yo-button
            (onClick)="yoErrorHelp.getHelp()"
            label="Détails"
            buttonClass="cta-secondary"></yo-button>
        </div>
      </div>
    </div>
  </dx-toast>
</ng-container>

<p-sidebar
  [closeOnEscape]="false"

  appendTo="body"
  [style]="{width:'90%'}"
  [(visible)]="utils.sidenav"
  position="right"
  (onHide)="onHideSideBar()"
  #sidebar
>
  <router-outlet name="secondary"></router-outlet>
  <ng-container *ngIf="key === 'sidebar'">
    <dx-toast id="toast-sidebar" key="sidebar" [width]="600" [displayTime]="timeMessage" [closeOnClick]="false" [(visible)]="isVisible" [type]="type">
    </dx-toast>
  </ng-container>
  <ng-container *ngIf="key === 'sidebar_center'">
    <dx-toast id="toast-sidebar-center" key="sidebar_center" [width]="600" [displayTime]="timeMessage" [closeOnClick]="false" [(visible)]="isVisible" [type]="type">
    </dx-toast>
  </ng-container>
</p-sidebar>

<router-outlet name="tertiary"></router-outlet>

<ng-container *ngIf="isLoggedIn$ | async; else notLogged">
  <div class="d-flex toolbar" [style.height.px]="HEADER_HEIGHT_PX">
    <div class="mr-auto mg-l-40">
      <yo-breadcrumb [selectedItem]="itemNavSvc.selectedMenuItem"></yo-breadcrumb>
    </div>
    <div>

      <ng-container *ngIf="environnement$ | async as environnement">
        <span
          class="font-12 cursor mg-r-10"
          title="Environnement sélectionné : {{selectedEnvironnementDTO?.libelle}}"

          (click)="initItemsMenuEnvrionnementsAndDisplay($event)"
          #environnement>
          <i class="fa fa-globe mg-r-5"></i>{{selectedEnvironnementDTO?.libelle}}
        </span>

        <p-menu id="menuEnvironnements" #menuEnvironnements  [popup]="true" [model]="itemsMenuEnvironnements"></p-menu>

      </ng-container>


      <span (click)="openPreferences()" #loggedIn [title]="getResponsiveLabelLogin('long')"
            class="font-12 cursor mg-r-10"
            >
        <i class="fa fa-user-circle mg-r-5"></i>
        {{getResponsiveLabelLogin(bandeauWidthType)}}
      </span>

      <span class="mg-r-10">
              <i (click)="logout()" class="fa fa-power-off font-22 mg-r-5 cursor"></i>
      </span>

    </div>
  </div>

  <div class="d-flex">
    <ng-container>
      <div class="d-flex left-small-menu">
        <yo-left-app-menu></yo-left-app-menu>
      </div>
      <div class="d-flex flex-grow-1">
        <main #mainContent class="content">
          <router-outlet></router-outlet>
        </main>
      </div>
    </ng-container>
  </div>
</ng-container>


<!--REDIRIGER VERS PAGE DE LOGIN-->
<ng-template #notLogged>
  <router-outlet></router-outlet>
</ng-template>

<div
  *ngIf="loading$ | async"
  class="loading-button"
  title="chargement en cours..."
  style="z-index:999999;">
  <dx-load-indicator
    id="large-indicator"
    height="80"
    width="80"
  ></dx-load-indicator>
</div>

<yo-help [dialogMsgSupplier]="utils.detailError" iconClick="hidden" [width]="800" #yoErrorHelp></yo-help>

<yo-preferences-dialog-edit></yo-preferences-dialog-edit>

<p-blockUI [blocked]="isBlockedUI"></p-blockUI>

<yo-wizard-configuration></yo-wizard-configuration>
